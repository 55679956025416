





































































import { Component } from "vue-property-decorator";
import { AccountService } from "@/core/services";
import { images } from "@/core/constants";
import { Message, Val } from "element-ui";
import AppVue from "@/AppVue.vue";
import { TermsAndConditionsCpt } from ".";
import { FooterCpt } from "@/core/components";
import { zohoManager } from "@/zoho/zoho";
import { AxiosError, AxiosStatic } from "axios";

@Component({
  components: {
    TermsAndConditionsCpt: () => Promise.resolve(TermsAndConditionsCpt),
    FooterCpt: () => Promise.resolve(FooterCpt),
  },
})
export default class LoginView extends AppVue {
  loading: boolean = false;
  centerDialogVisible: boolean = false;
  year = new Date().getFullYear();
  form = {
    email: "",
    password: "",
  };
  rules = {
    email: [
      {
        required: true,
        message: "Your email address is required",
        trigger: "blur",
      },
      { min: 3, max: 5, message: "Length should be 3 to 5", trigger: "blur" },
    ],
  };
  model = {};
  logo = images.cisLogoCropped;

  async created() {
    if (this.$route.params.loginKey) {
      this.trackEvent("Autologin", { name: "autologin-begin" });

      this.loading = true;
      const response = await AccountService.autologin(this.$route.params.loginKey);
      if (response) {
        this.trackEvent("Autologin", { name: "autologin-success" });
        AccountService.setToken(response.token);
        this.redirectToResult();
      } else {
        this.trackEvent("Autologin", { name: "autologin-failure" });
      }
    }
  }

  async cancel() {
    this.$notify({
      title: "Terms not accepted",
      showClose: true,
      message: "Logging out",
      type: "error",
      duration: 0,
    });
    await AccountService.logout();
    this.redirectToResult();
  }
  async accept() {
    const result = await AccountService.acceptCisTerms();
    if (result.data.hasAcceptedTerms) {
      this.redirectToResult();
      return;
    }
    this.$notify({
      title: "Terms update failed",
      showClose: true,
      message: "Logging out",
      type: "error",
      duration: 0,
    });
    await AccountService.logout();
  }
  redirectToResult() {
    if (this.$auth.isAuthenticated()) {
      if (this.$route.query.redirectTo) {
        this.trackEvent("Login", {
          name: "login-redirect",
          route: this.$route.query.redirectTo,
        });
        this.$router.push(this.$route.query.redirectTo as string);
        return;
      }
      this.$router.push("/dashboard").catch(() => {});
    } else {
      window.location.reload();
    }
  }

  redirectToOverlappingLogin() {
    if (this.$auth.isAuthenticated()) {
      if (this.$route.query.redirectTo) {
        this.trackEvent("Login", {
          name: "login-overlapping",
          route: "/loginOverlapping",
          query: this.$route.query.redirectTo,
        });
        this.$router.push({
          name: "overlapping-login",
          query: { redirectTo: this.$route.query.redirectTo },
        });
        return;
      }
      this.$router.push({ name: "overlapping-login" });
    } else {
      window.location.reload();
    }
  }

  async login(formRef: string) {
    Message.closeAll();
    this.loading = true;
    try {
      const response = await AccountService.login(this.form);

      AccountService.setToken(response.token);
      if (AccountService.getMachineCode() === "") {
        // this login has no session and will fail any api call from now on.
        return this.redirectToOverlappingLogin();
      }
      if (response) {
        this.loading = false;
        zohoManager.UpdateUser(this.form.email);
        const profile = await AccountService.getProfile();

        if (!profile.hasAcceptedTerms) {
          this.centerDialogVisible = true;
          return;
        }
        this.redirectToResult();
      }
    } catch (err) {
      const error = err as AxiosError;
      this.$notify({
        title: "Login failed",
        showClose: true,
        message: error?.response?.data ?? "Incorrect username/password provided",
        type: "error",
        duration: 0,
      });
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    super.updateTitle("Log in");
  }
}
